import {ChangeDetectionStrategy, Component, OnChanges, OnInit, SimpleChanges, input} from '@angular/core';
import {IPackageReward} from './../../../services/packageTypes';
import {RouterLink} from '@angular/router';


@Component({
    selector: 'oex-reward-list',
    templateUrl: 'reward-list.component.html',
    styleUrls: ['reward-list.component.scss'],
    imports: [
        RouterLink
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RewardListComponent implements OnInit, OnChanges {
  readonly rewards = input<IPackageReward[]>([]);
  readonly showContestNames = input(false);

  ngOnInit() {
    // this.rewards = [...this.rewards, ...this.rewards, ...this.rewards];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['rewards'] && changes['rewards'].previousValue !== changes['rewards'].currentValue) {
      this.prepareAwards();
    }
  }

  private prepareReward(r: IPackageReward) {
    const postfix = ['', 'st', 'nd', 'rd'];
    r.placeUI = r.place + (postfix[r.place] ?? '');
  }

  private prepareAwards() {
    this.rewards()?.forEach(r => this.prepareReward(r));
  }
}
