<!--<h1>{{rewards.length}} Awards</h1>-->
<div class="list">
  @for (r of rewards(); track r) {
    <div class="item">
      <div class="img">
        @if (r.type !== 'badge' && r.nomination !== 'Experts') {
          <img [src]="'/assets/img/rewards/community-' + r.place + '.svg'">
        }
        @if (r.type !== 'badge' && r.nomination === 'Experts') {
          <img [src]="'/assets/img/rewards/expert-' + r.place + '.svg'">
        }
        @if (r.image_url) {
          <img [src]="r.image_url">
        }
      </div>
      <div class="details">
        <div class="place">{{r.placeUI || r.place}} place</div>
       <!-- <h3><a class="ui-link text" [routerLink]="'/contest/' + r.contestId">{{r.contestName || r.contest_name}}</a></h3>
        @if (r.nomination && !showContestNames) {
          <h4>{{r.nomination}} nomination</h4>
        }
        @if (r.contest_name && showContestNames) {
          <h4><a class="ui-link text" [routerLink]="'/contest/' + r.contestId">{{r.contest_name}}</a></h4>
        }-->
        @if (r.type === 'badge') {
          <h3 [title]="r.nomination">{{r.nomination}}</h3>
          <h4>{{r.description}}</h4>
        } @else {
          @if (r.solution_name) {
            <h3><a class="ui-link text" [routerLink]="'/package/' + r.solution_nws">{{r.solution_name}}</a></h3>
          }
          <h4><a class="ui-link text" [routerLink]="'/contest/' + r.contestId">{{r.contestName || r.contest_name}}</a></h4>
        }
      </div>
    </div>
  }
</div>

